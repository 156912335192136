<template>
    <header class="header van-hairline--bottom">
        <i v-if="isback" class="icon" @click="goBack">
            <van-icon name="arrow-left" />
        </i>
        <div class="header-name">{{ $t('LAN.forget') }}</div>
    </header>
    <div class="block" />
    <div class="forgot" style="">
        <div v-if="type == 'default'" class="default-body">
            <van-grid clickable :column-num="1" gutter="5">
                <van-grid-item icon="envelop-o" :text="$t('LAN.forgot_from_email')" @click="type = 'email', isback=true"/>
            </van-grid>
        </div>
        
        <van-empty v-if="finish"
            class="custom-image"
            :image='require(`@/assets/common/success.png`)'
            :description="$t('LAN.success')"/>

        <div class="content" v-else>
            <!-- 绑定邮箱找回 -->
            <div v-if="type == 'email'">
                <van-empty v-if="success"
                class="custom-image"
                :image='require(`@/assets/common/success.png`)'
                :description="$t('LAN.success')"/>
                <van-form v-else @submit="onForgot">
                    <van-field
                        v-model="email"
                        :label="$t('LAN.email')"
                        :placeholder="$t('LAN.input_email')"
                        clearable
                        center
                        left-icon="envelop-o"
                        :rules="[{ validator:validateEmail, required: true, message:  $t('LAN.input_validator_email') }]"
                    />
                    <van-field
                        v-model="verifyCode"
                        name="verifyCode"
                        :label="$t('LAN.verify_code')"
                        center
                        left-icon="envelop-o"
                        :placeholder="$t('LAN.input_new_verify_code')"
                        :rules="[{ required: true, message: $t('LAN.input_new_verify_code') }]">
                        <template #button>
                            <van-button size="small" type="primary" color="#f3b134" @click="sendVerify" :disabled="verifyDisabled">{{ verifyBtnText }}</van-button>
                        </template>
                    </van-field>

                    <van-field
                        v-model="newPassword"
                        type="password"
                        maxlength="6"
                        clearable
                        :label="$t('LAN.new_pwd')"
                        left-icon="lock"
                        :placeholder="$t('LAN.input_pwd')"
                        :rules="[{ validator:validatorPwd, required: true, message: $t('LAN.input_pwd') }]" />

                    <van-field
                        v-model="confirmPassword"
                        type="password"
                        maxlength="6"
                        clearable
                        :label="$t('LAN.confirm_pwd')"
                        left-icon="lock"
                        :placeholder="$t('LAN.input_confirm_pwd')"
                        :rules="[{ validator:validatorConfirmPwd, required: true, message: $t('LAN.input_confirm_pwd') }]" />

                    <div style="margin: 16px;">
                        <van-button round block color="#6e87ea" native-type="submit">{{$t('LAN.reset_password')}}</van-button>
                    </div>
                </van-form>
                <div class="email-tips" v-if="!success" >
                    <p>{{$t('LAN.tips')}}</p>
                    <p>{{$t('LAN.email_tips')}}</p>
                </div>
            </div>
        </div>    
    </div>

</template>

<script>
import { reactive, onMounted, toRefs, getCurrentInstance} from 'vue'
import { feedbackMenus } from '@/configs/feedback' 
import { useRoute, useRouter} from 'vue-router'
import { formatDate } from '@/utils/common'
import { useStore } from 'vuex'
import { requestEmailVerifycode } from '@/service/user'
import { requestPayPasswordForgot} from '@/service/pay'
import { useI18n } from 'vue-i18n'
import { Toast } from 'vant'
import { validateNull, validateEmail} from '@/utils/validate';

import uploadFile from '@/utils/uploadFile'


export default {
    setup() {
        
        const route = useRoute()
        const router = useRouter()
        const store = useStore()
        const { t } = useI18n()
        const { proxy } = getCurrentInstance();

        const state = reactive({
            finish:false,
            success:false,
            isback:false,
            needback:true,
            type: 'default',
            username: '',
            newPassword: '',
            confirmPassword:'',
            
            email: '',
            verifyCode:'',
            verifyTime:0,
            verifyDisabled: false,
            verifyBtnText: t('LAN.send_verify_code'),

            gameId:0,
            minDate: new Date(2018, 6, 1),
            maxDate: new Date(),

            menus: feedbackMenus,
            menuType:4,

        })

        onMounted(() => {
            route.query.lang
            if (!validateNull(route.query.gameId)) {
                state.gameId = route.query.gameId
            } else {
                state.gameId = store.getters.gameId
            }

            if (!validateNull(route.query.lang)) {
                proxy.$I(proxy,route.query.lang)
            }

            initData();
            checkCanBack();
        });

        const initData = () => {
            state.success = false;
            state.username = '';
            state.newPassword = '';
            state.confirmPassword = '';
            state.verify = '';
            state.verifyTime = 0;
            state.verifyTime = 0;
            state.verifyDisabled = false;
            state.verifyBtnText = t('LAN.send_verify_code');

        }
        
        const goBack = () => {
            if (state.type != 'default') {
                state.type = 'default';
            } else {
                if (state.needback) {
                    router.go(-1);
                }
            }
            checkCanBack();
        }

        const checkCanBack = () => {
            state.isback = false;
            if (state.type == 'default') {
                if (state.needback) {
                    state.isback = true;
                }
                initData();
            }
        }

        const onConfirmTime = (item) => {
            item.value = formatDate(item.currentDate, "YY-MM-DD")
            item.timePicker = false;
        }

        const afterRead = (file) => {
            let upload = function(file) {
                file.status = 'uploading';
                file.message = t('LAN.uploading');
                uploadFile(file.file, function(url) {
                    if (validateNull(url)) {
                        file.status = 'failed';
                        file.message = t('LAN.upload_failed');
                    } else {
                        file.status = 'success';
                        file.message = '';
                        file.url = url
                    }
                })
            }
            if (file.length > 0) {
                for (let i = 0; i < file.length; i++) {
                    upload(file[i])
                }
            } else {
                upload(file)
            }
        }

        const onSubmit = () => {
            let params = {
                email: state.email,
            };

            let inpus = state.menus[4].inputs;
            for (let i = 0; i < inpus.length; i++) {
                let input = inpus[i];
                if (input.required) {
                    if (validateNull(input.value)) {
                        Toast.fail(t(input.placeholder))
                        return;
                    }
                }
            }
            if (validateNull(state.email)) {
                Toast.fail(t('LAN.input_contact_email'));
                return;
            }
            if (!validateEmail(state.email)) {
                Toast.fail(t('LAN.input_validator_email'));
                return;
            }

            console.log(params)
        }

        const sendVerify = async () => {
            if (validateNull(state.email) || !validateEmail(state.email)) {
                Toast(t('LAN.input_validator_email'))
                return;
            }
            Toast.loading(t('LAN.sending'));
            await requestEmailVerifycode({email:state.email});
            state.verifyTime = 30
            onVerifyTimer()
            Toast.success(t('LAN.send_success'))
        }

        const onVerifyTimer = () => {
            if (state.verifyTime > 0) {
                state.verifyDisabled = true;
                state.verifyTime --;
                state.verifyBtnText = `${t('LAN.resend')}(${state.verifyTime})`;
                setTimeout(onVerifyTimer, 1000);
            } else{
                state.verifyTime = 0;
                state.oldVerifyBtnText = t('LAN.send_verify_code');
                state.verifyDisabled = false;
            }
        }

        const validatorPwd = () => {
            if (state.newPassword.length != 6 || !(/^\d{6}$/.test(state.newPassword)) ) {
                return false;
            }
            return true;
        }

        const validatorConfirmPwd = () => {
            return state.confirmPassword == state.newPassword;
        }

        const onForgot = async () => {
            Toast.loading(t('LAN.sending'));
            await requestPayPasswordForgot({
                email:state.email,
                verifyCode:state.verifyCode,
                password:state.newPassword,
                passwordConfirmation:state.confirmPassword
            });
            store.commit('setPayPasswordFlag', true);
            state.success = true;
            Toast(t('LAN.success'));
        }

        return {
            ...toRefs(state),
            goBack,
            onConfirmTime,
            afterRead,
            onSubmit,
            onForgot,
            sendVerify,
            validatorPwd,
            validatorConfirmPwd,
            validateEmail,
        }
    },
    components: {

    }
}
</script>

<style lang="less" scoped>
@import '../../style/mixin.less';
.header {
   // position: fixed;
   // top: 0;
   // left: 0;
    .wh(100%, 50px);
    line-height: 50px;
    padding: 0 10px;
    .boxSizing();
    justify-content: center;
    color: #252525;
    background: #fff;
    .icon {
      position: absolute;
      left: 10px;
      font-size: 23px;
    }
    .header-name {
      width: 100%;
      font-size: 16px;
    }
}
.block {
    height: 50px;
}

/deep/ .custom-image .van-empty__image {
    width: 100px;
    height: 100px;
}

.forgot {
    height: 90vh;
}

.default-body {
    margin: 20px;
    height: 100px;
}

.email-tips {
    margin: 20px;
    text-align: left;
    color: #8c8c8c;
    font-size: 14px;
}

iframe {
    height: 100%;
    width: 100%;
}
</style>