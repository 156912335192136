import axios from '../utils/axios'

export function payOrder(params) {
    return axios.get('/pay', { params })
}
export function requestPointsInfo() {
    return axios.post('/points/info', {})
}

export function requestPayPassword(params) {
    return axios.post('/points/payPassword', params)
}

export function requestPayPasswordForgot(params) {
    return axios.post('/points/forgot', params)
}

export function requestPayPasswordVerify(params) {
    return axios.post('/points/pay', params)
}

export function requestPayMode(params) {
    return axios.post('/mode', params)
}
export function requestPay(params) {
    return axios.post('/pay', params)
}

export function requestPayfale(params){
    return axios.post('/points/payfale', params);
}

export function gameConfig(id) {
    return axios.get(`/game/${id}`)
}